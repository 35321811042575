import { Module } from "vuex";
import { State } from "@/store/state";

interface OrderState {
  sportOrderList: any;
}

const sportTab = {
  title: "betOrder.type.sports",
  path: "/betOrder/sports",
};
const lotteryTab = {
  title: "betOrder.type.lottery_tab",
  path: "/betOrder/lottery",
};
const slotTab = {
  title: "betOrder.type.casino",
  path: "/betOrder/casino",
};

const tabsMap = new Map<string, any>([
  ["4", sportTab],
  ["2", lotteryTab],
]);
const topBtns = [sportTab, lotteryTab, slotTab];

export const order: Module<OrderState, State> = {
  namespaced: true,
  state: {
    sportOrderList: null,
  },
  getters: {
    orderTabsSorted(state, getters, rootState, rootGetters) {
      const temps = rootGetters.gameNavigation.filter(
        (v: any) => v.id != "7" && v.id != "8"
      );
      // 用于存放排序后的 tabs
      const sortedTabs: any[] = [];
      // 用于存放没有匹配到的 tabs
      const unmatchedTabs: any[] = [];
      // 遍历 tabs，根据数组一的顺序排序
      for (const value of temps) {
        const tab = tabsMap.get(value.id);
        // if (!sortedTabs.includes(tab))
        if (tab && !sortedTabs.includes(tab)) {
          sortedTabs.push(tab);
        } else if (!tab && !sortedTabs.includes(slotTab)) {
          sortedTabs.push(slotTab);
        }
      }
      for (const tab of topBtns) {
        if (!sortedTabs.includes(tab)) {
          unmatchedTabs.push(tab);
        }
      }

      return sortedTabs.concat(unmatchedTabs);
    },
  },
  actions: {},
  mutations: {},
};
