import { Module } from "vuex";
import { State } from "@/store/state";
import { isNumeric } from "@/utils/util";

export interface rebatesState {
  categoryList: any[]; //左侧导航
  userRebateInfos: any[]; //用户返利列表（所有类型）
  categoryActive: number; //当前选中分类的索引
  rebateSwitch: boolean;
}

export const rebates: Module<rebatesState, State> = {
  namespaced: true,
  state: {
    categoryList: [],
    userRebateInfos: [],
    categoryActive: 0,
    rebateSwitch: false,
  },
  getters: {
    // 分类列表
    categoryList: (state) => state.categoryList,
    // 当前选中分类对应的返利列表
    UserRebateInfo: (state) => (index: number) => {
      const categoryId = state.categoryList[index]?.categoryId;
      return state.userRebateInfos.filter(
        (v: any) => v.categoryId == categoryId
      )[0]?.list;
    },
    rebateSwitch: (state) => state.rebateSwitch,
    hasRedDot: (state, getters, rootState, rootGetters) => {
      if (!rootGetters.loginSuccess) {
        return false;
      }

      let res = false;
      for (let i = 0; i < state.categoryList.length; i++) {
        const t = getters.hasRedDotFor(i);
        if (t) {
          res = true;
          break;
        }
      }

      return res;
    },
    hasRedDotFor:
      (state, getters, rootState, rootGetters) => (index: number) => {
        if (!rootGetters.loginSuccess) {
          return false;
        }

        const info = getters.UserRebateInfo(index);
        return (
          Array.isArray(info?.rebates) &&
          info.rebates.some(
            (value: any) =>
              isNumeric(value.yesterdayCashBack) &&
              Number(value.yesterdayCashBack) > 0
          )
        );
      },
  },
  actions: {},
  mutations: {
    clearData(state) {
      state.userRebateInfos = [];
      state.categoryActive = 0;
    },
    saveCategoryList(state, payload) {
      payload.list.forEach((v: any) => {
        if (v.categoryId == "1")
          v.icon = require("@/assets/images/nb/discountHall/activity-2.png");
        if (v.categoryId == "3")
          v.icon = require("@/assets/images/nb/discountHall/activity-6.png");
        if (v.categoryId == "4")
          v.icon = require("@/assets/images/nb/discountHall/activity-5.png");
        if (v.categoryId == "5")
          v.icon = require("@/assets/images/nb/discountHall/activity-1.png");
        if (v.categoryId == "6")
          v.icon = require("@/assets/images/nb/discountHall/activity-3.png");
        if (v.categoryId == "7")
          v.icon = require("@/assets/images/nb/discountHall/activity-4.png");
      });
      state.categoryList = payload.list;
    },
    saveUserRebateMap(state, payload) {
      const { categoryId, value } = payload;
      const i = state.userRebateInfos.findIndex(
        (v: any) => v.categoryId == categoryId
      );
      if (i == -1) {
        state.userRebateInfos = JSON.parse(
          JSON.stringify([
            ...state.userRebateInfos,
            {
              categoryId,
              list: value,
            },
          ])
        );
      } else {
        const newList = JSON.parse(JSON.stringify(state.userRebateInfos));
        newList[i].list = value;
        state.userRebateInfos = newList;
      }
    },
    updateCategoryActive(state, index) {
      state.categoryActive = index;
    },
    saveRebateSwitch(state, payload) {
      state.rebateSwitch = payload;
    },
  },
};
