import { Module } from "vuex";
import { State } from "@/store/state";
import { NBRechargeRecordSection, NbListConfig } from "@/interface";
import types from "@/store/mutation-types";

const DefaultConfig = { page: 1, loading: false, finished: true };

export interface RechargeState {
  rechargeRecord: NBRechargeRecordSection[] | null;
  rechargeRecordConfig: NbListConfig | null;
}

export const recharge: Module<RechargeState, State> = {
  namespaced: true,
  state: {
    rechargeRecord: null,
    rechargeRecordConfig: null,
  },
  getters: {
    rechargeRecord(state) {
      return state.rechargeRecord;
    },
    rechargeRecordConfig(state) {
      return state.rechargeRecordConfig ?? { ...DefaultConfig };
    },
    rechargeDetail: (state) => (no: string) => {
      let obj;

      if (state.rechargeRecord) {
        for (let i = 0; i < state.rechargeRecord.length; i++) {
          const temp = state.rechargeRecord[i].records.find((r) => r.no === no);
          if (temp) {
            obj = temp;
            break;
          }
        }
      }

      return obj;
    },
  },
  actions: {},
  mutations: {
    clearData(state) {
      state.rechargeRecord = null;
      state.rechargeRecordConfig = null;
    },
    [types.RECHARGE_RECORD_UPDATE](state, payload) {
      if (!state.rechargeRecord) {
        state.rechargeRecord = [];
      }
      if (payload.type === "clear") {
        state.rechargeRecord = [];
      } else if (payload.type === "add") {
        state.rechargeRecord.push(...payload.data);
      }
    },
    [types.RECHARGE_RECORD_CONFIG_UPDATE](state, payload) {
      if (!state.rechargeRecordConfig) {
        state.rechargeRecordConfig = { ...DefaultConfig };
      }

      state.rechargeRecordConfig = {
        ...state.rechargeRecordConfig,
        ...payload,
      };
    },
    [types.RECHARGE_RECORD_RESET](state) {
      state.rechargeRecord = null;
      state.rechargeRecordConfig = null;
    },
  },
};
