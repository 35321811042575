// import { store } from "@/store";
import { store } from "@/store";

let deferredPrompt: any = null;
window.addEventListener("beforeinstallprompt", (e) => {
  console.log("Pwa::" + " beforeinstallprompt", e);
  e.preventDefault();
  deferredPrompt = e;
  // 进入到这里面，安装准备已经就绪。若桌面有安装不会进入该回调
  store.commit("updateChromiumReadyPwa", true);
  localStorage.removeItem("addDesktop");
});

window.addEventListener("appinstalled", () => {
  deferredPrompt = null;
  console.log("Pwa::" + "app installed");
  // 安卓chrome安装webapp之后可以进入到这里
  localStorage.setItem("addDesktop", "1");
});

export const installWebApp = async () => {
  if (deferredPrompt != null) {
    deferredPrompt.prompt();
    const { outcome } = await deferredPrompt.userChoice;
    if (outcome === "accepted") {
      console.log("Pwa::" + "start install webapp");
      return true;
    } else {
      console.log("Pwa::" + "user cancel install");
      return false;
    }
  } else {
    console.log("Pwa::" + "beforeinstallprompt not ready");
    return false;
  }
};
