import i18n from "@/locales";
import { showConfirmDialog, showDialog } from "vant";
import { useCustomRouter } from "@/router/custRouter";
import { ROUTE_LOGIN } from "@/router/paths";

interface NbDialogOptions {
  title?: string;
  message: string;
  cancel?: string;
  confirm?: string;
  allowHtml?: boolean;
  noOverly?: boolean;
  notCloseOnPopstate?: boolean;
}

export const showNbConfirmDialog = ({
  title,
  message,
  cancel,
  confirm,
  allowHtml,
  noOverly,
  notCloseOnPopstate, // 是否在页面回退时自动关闭。点击提现去注册回来时不需要关闭
}: NbDialogOptions) => {
  return showConfirmDialog({
    title: title ?? i18n.global.t("dialog.WARNING_TITLE"),
    message,
    className: "as-dialog-default",
    cancelButtonText: cancel ?? i18n.global.t("dialog.CANCEL_BTN"),
    confirmButtonText: confirm ?? i18n.global.t("dialog.CONFIRM_BTN"),
    allowHtml,
    overlayClass: noOverly ? "nb-back-game-no-overlay" : undefined,
    closeOnPopstate: !notCloseOnPopstate,
  });
};

export const showNbDialog = ({ title, message, confirm }: NbDialogOptions) => {
  return showDialog({
    title: title ?? i18n.global.t("dialog.WARNING_TITLE"),
    message,
    className: "as-dialog-default",
    confirmButtonText: confirm ?? i18n.global.t("dialog.CONFIRM_BTN"),
    teleport: "#app",
  });
};

export const showNBGuestLimitDialog = () => {
  showNbConfirmDialog({
    message: i18n.global.t("dialog.GUEST_LIMIT.MESSAGE"),
    confirm: i18n.global.t("dialog.GUEST_LIMIT.CONFIRM"),
  }).then(() => {
    useCustomRouter(null).push({ path: ROUTE_LOGIN.path });
  });
};
