import { Module } from "vuex";
import { State } from "@/store/state";
import { NBWithdrawRecordSetion, NbListConfig } from "@/interface";

const DefaultConfig = { page: 1, loading: false, finished: true };

export interface WithdrawState {
  withdrawRecord: NBWithdrawRecordSetion[] | null;
  withdrawRecordConfig: NbListConfig | null;
}

export const withdraw: Module<WithdrawState, State> = {
  namespaced: true,
  state: {
    withdrawRecord: null,
    withdrawRecordConfig: null,
  },
  getters: {
    withdrawRecord(state) {
      return state.withdrawRecord;
    },
    withdrawRecordConfig(state) {
      return state.withdrawRecordConfig ?? { ...DefaultConfig };
    },
    withdrawDetail: (state) => (no: string) => {
      let obj;

      if (state.withdrawRecord) {
        for (let i = 0; i < state.withdrawRecord.length; i++) {
          const temp = state.withdrawRecord[i].records.find((r) => r.no === no);
          if (temp) {
            obj = temp;
            break;
          }
        }
      }

      return obj;
    },
  },
  actions: {},
  mutations: {
    saveWithdrawRecord(state, payload) {
      if (!state.withdrawRecord) {
        state.withdrawRecord = [];
      }
      if (payload.type === "clear") {
        state.withdrawRecord = [];
      } else if (payload.type === "add") {
        state.withdrawRecord.push(...payload.data);
      }
    },
    saveWithdrawRecordConfig(state, payload) {
      if (!state.withdrawRecordConfig) {
        state.withdrawRecordConfig = { ...DefaultConfig };
      }

      state.withdrawRecordConfig = {
        ...state.withdrawRecordConfig,
        ...payload,
      };
    },
    updatePayment(state, payload) {
      state.withdrawRecord?.forEach((v) => {
        const temps = v.records.find((r) => r.no === payload);
        if (temps) temps.statusId = "3";
      });
    },
    resetWithdrawRecord(state) {
      state.withdrawRecord = null;
      state.withdrawRecordConfig = null;
    },
  },
};
