import {
  AgentPublicitySetting,
  AgentDistributionRule,
  DistributionRebateStatistics,
  AgentInvitationCode,
  CommissionDetailList,
  CommissionList,
  AgentMemberList,
} from "@/interface";

import request from "@/request/http/index";
import { requestPost, requestPostPromise } from "@/request/http/http";
import { store } from "@/store";

const agentLogic = {
  apply: (callback: () => void) => {
    requestPost(request.agentApi.Apply, null, () => {
      callback();
    });
  },
  getBecomeAgent: () => {
    requestPost(request.agentApi.BecomeAgent, null, (response) => {
      if (store.hasModule("agent")) {
        store.commit("agent/saveBecomeAgent", { data: response });
      }
    });
  },
  getInvitationCode: (type: number) => {
    requestPost(request.agentApi.InvitationCode, { type: type }, (response) => {
      if (store.hasModule("agent")) {
        const res = response as AgentInvitationCode | null;
        store.commit("agent/saveInvitationCode", { data: res });
      }
    });
  },
  getInvitationApps: () => {
    requestPost(request.agentApi.InvitationApps, null, (response) => {
      if (store.hasModule("agent")) {
        store.commit("agent/saveInvitationApps", { data: response });
      }
    });
  },
  getPublicitySetting: () => {
    requestPost(request.agentApi.PublicitySetting, null, (response) => {
      if (response) {
        const res = response as AgentPublicitySetting | null;
        if (res == null) {
          return;
        }
        if (store.hasModule("agent")) {
          store.commit("agent/savePublicitySetting", { data: res });
        }
      }
    });
  },
  getAgentRules: () => {
    requestPost(request.agentApi.AgentRules, null, (response) => {
      if (response && response.rule) {
        const res = response.rule as string | "";
        if (store.hasModule("agent")) {
          store.commit("agent/saveAgentRules", { data: res });
        }
      }
    });
  },
  getAgentDistributionRule: () => {
    requestPost(request.agentApi.AgentDistributionRule, null, (response) => {
      if (response) {
        const res = response as AgentDistributionRule[];
        if (res == null) {
          return;
        }
        if (store.hasModule("agent")) {
          store.commit("agent/saveDistributionRule", { data: res });
        }
        // callback(res);
      }
    });
  },
  getDistributionRebateStatistics: () => {
    requestPost(
      request.agentApi.DistributionRebateStatistics,
      null,
      (response) => {
        if (response) {
          const res = response as DistributionRebateStatistics | null;
          if (res == null) {
            return;
          }
          if (store.hasModule("agent")) {
            store.commit("agent/saveRebateStatistics", { data: res });
          }
        }
      }
    );
  },
  getMemberList: (
    params: any,
    callbackFunc: (value: AgentMemberList) => void,
    finallyFunc: () => void
  ) => {
    requestPostPromise(
      request.agentApi.MemberList,
      params,
      null,
      params.page === 1
    )
      .then((response) => {
        const res = response.data as AgentMemberList | null;
        if (res == null) {
          return;
        }
        callbackFunc(res);
      })
      .finally(() => {
        finallyFunc();
      });
  },
  getCommissionList: (
    params: any,
    callbackFunc: (value: CommissionList) => void,
    finallyFunc: () => void
  ) => {
    requestPostPromise(
      request.agentApi.RebateRecordList,
      params,
      null,
      params.page === 1
    )
      .then((response) => {
        const res = response.data as CommissionList | null;
        if (res == null) {
          return;
        }
        callbackFunc(res);
      })
      .finally(() => {
        finallyFunc();
      });
  },
  getCommissionDetail: (
    params: any,
    callbackFunc: (value: CommissionDetailList) => void,
    finallyFunc: () => void
  ) => {
    requestPostPromise(request.agentApi.RebateDetail, params, null)
      .then((response) => {
        const res = response.data as CommissionDetailList | null;
        if (res == null) {
          return;
        }
        callbackFunc(res);
      })
      .finally(() => {
        finallyFunc();
      });
  },
};

export default agentLogic;
