import { ref } from "vue";
import { Router } from "vue-router";
import { ROUTE_LOGIN, ROUTE_REGISTER } from "./paths";

let proxyRouter: Router | null = null;
const needAnimate = ref(false); // 处理Safari等浏览器自带手势切换页面时，不执行过渡动画

export const useRouterAnimate = () => {
  return needAnimate;
};
export const useCustomRouter = (router: any) => {
  if (proxyRouter) {
    return proxyRouter;
  }

  proxyRouter = new Proxy<Router>(router, {
    get(target, props, receiver) {
      console.log("333proxy get", target.currentRoute.value, props, receiver);
      if (
        ["push", "back", "go"].includes(props as any) ||
        (props == "replace" &&
          [ROUTE_LOGIN.path, ROUTE_REGISTER.path].includes(
            target.currentRoute.value.path
          ))
      ) {
        needAnimate.value = true;
      } else {
        needAnimate.value = false;
      }
      return Reflect.get(target, props, receiver);
    },
  });
  return proxyRouter;
};
