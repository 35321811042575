import { store } from "@/store";
import API from "@/request/http";
import { requestPost } from "@/request/http/http";
import types from "@/store/mutation-types";

const infoLogic = {
  getNbUserInfo: () => {
    requestPost(API.userApi.NBGetUserInfo, null, (response) => {
      store.dispatch("user/updateUserInfo", response);
    });
  },
  getBalance: () => {
    requestPost(API.userApi.UserBalance, null, (response) => {
      store.commit(`user/${types.UPDATE_USER_BALANCE}`, response);
    });
  },
  getAvatarList: (callback: (value: boolean) => void) => {
    requestPost(
      API.userApi.NBGetHead,
      null,
      (response) => {
        if (response == null) return;
        callback(response);
      },
      null,
      null,
      true
    );
  },
};

export default infoLogic;
