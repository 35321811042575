import { Module } from "vuex";
import { State } from "@/store/state";
import { MessagePopupPriority } from "@/constant/component";

export class MessagePopupData implements IMessagePopupData {
  public componentName: any;
  public priority: MessagePopupPriority;
  public data: any;

  constructor(componentName: any, priority: MessagePopupPriority, data: any) {
    this.componentName = componentName;
    this.priority = priority;
    this.data = data;
  }
}

interface IMessagePopupData {
  componentName: any; //组件
  priority: MessagePopupPriority; //优先级
  data: any; //弹窗需要的数据
}

//弹窗消息队列
export interface MessagePopupBoxState {
  popupData: IMessagePopupData | null; //当前展示弹窗的数据
  messageQueue: IMessagePopupData[]; //存在弹窗消息的队列，先进先出
}

export const messagePopupBox: Module<MessagePopupBoxState, State> = {
  namespaced: true,
  state: {
    popupData: null,
    messageQueue: [],
  },
  getters: {
    shouldShow(state) {
      return state.popupData != null;
    },
  },
  actions: {
    addNewMessage({ commit }, payload) {
      commit("pushMessage", payload);
      // commit('sortMessage')
      commit("popMessage");
    },
    closeMessagePopup({ commit }) {
      commit("closeBox");
      //关闭当前弹窗后检查队列中是否有其他需要弹出的
      commit("popMessage");
    },
    closeMessagePopupOnly({ commit }) {
      // 只关闭当前，先不弹出下一个
      commit("closeBox");
    },
  },
  mutations: {
    pushMessage(state, payload) {
      //插入消息。消息唯一，需要排除可能出现的重复
      // const index = state.messageQueue.findIndex(v => v.priority === payload.message.priority)
      // if (index == -1) {
      state.messageQueue.push(payload.message);
      // } else {
      // state.messageQueue[index] = payload.message
      // }
    },
    // sortMessage(state) {
    //   //优先级高的排前面
    //   state.messageQueue.sort((a, b) => b.priority - a.priority)
    // },
    popMessage(state) {
      //如果当前未显示弹窗，则把优先级最高的拿出来
      if (state.popupData == null) {
        state.popupData = state.messageQueue.shift() ?? null;
      }
    },
    closeBox(state) {
      state.popupData = null;
    },
    clearData(state) {
      state.messageQueue = [];
      state.popupData = null;
    },
  },
};
