import { Module } from "vuex";
import { State } from "@/store/state";

import { CATEGORY_BTN_TYPE } from "@/utils/consts";

export interface GamesState {
  btnList: any[];
  categoryId: number;
  platformId: string | null;
  platformList: any[];
}

export const games: Module<GamesState, State> = {
  namespaced: true,
  state: {
    btnList: [],
    categoryId: CATEGORY_BTN_TYPE.total,
    platformId: null,
    platformList: [],
  },
  getters: {
    platformList: (state) => (id: string | null) => {
      if (id) return state.platformList.filter((v: any) => v.id == id);
      else return state.platformList;
    },
  },
  actions: {
    saveBtnList({ commit }, payload) {
      commit("saveBtnList", payload);
    },
  },
  mutations: {
    saveBtnList(state, payload) {
      state.btnList = payload.btnList;
    },
    changeGameCollect(state, payload) {
      const gameId = payload?.gameId;
      if (!gameId) return;
      const newBtnList = state.btnList;
      newBtnList[0].list.forEach((value: any) => {
        if (value.id == gameId) value.isFavorite = "0";
      });
      state.btnList = newBtnList;
    },
    changeCategoryId(state, payload) {
      state.categoryId = payload.categoryId;
    },
    changePlatformId(state, payload) {
      state.platformId = payload.platformId;
    },
    savePlatFormList(state, payload) {
      state.platformList = payload.list;
    },
    updateNavGameCollect(state, payload) {
      let temp;
      if (payload.type == 1) {
        temp = state.btnList.find(
          (v) => v.type == 1 && v.platformId == payload.otherId
        );
      } else if (payload.type == 3) {
        temp = state.btnList.find(
          (v) => v.type == 3 && v.categoryId == payload.otherId
        );
      }

      if (temp && Array(temp.list)) {
        const res = temp.list.find((v: any) => v.id == payload.gameId);
        if (res) {
          res.isFavorite = payload.res;
        }
      }
    },
  },
};
