import infoLogic from "@/scripts/infoLogic";
import vipLogic from "@/scripts/vipLogic";
import agentLogic from "@/scripts/agentLogic";
import { NBOAuthConfig, BrandConfig, LanguageConfig } from "@/interface";
import { store } from "@/store";
import request, {
  PublicityApi,
  MessageApi,
  AnnouncementApi,
  BrandApi,
  generalApi,
  RouletteApi,
  GamePlatformApi,
  ChallengeApi,
  ActivityApi,
} from "@/request/http/index";
import { requestPost, requestPostPromise } from "@/request/http/http";
import { nbConfigApi } from "@/request/http/index";
import types from "@/store/mutation-types";
import { NBOcType } from "@/utils/emun";
import myWindow from "@/utils/myWindow";
import activityLogic from "./activityLogic";
import { requestGameNavigationList } from "@/scripts/gameLogic";
import customStore from "@/request/http/store";
import {
  addPrizeWheelPopup,
  addDailyCheckPopup,
  addPublicityImagePopup,
  addPublicityTextPopup,
  addFirstRechargeDiscountPopup,
  addManualRechargePopup,
} from "@/scripts/popupLogic";
import { CATEGORY_BTN_TYPE } from "@/utils/consts";
// import { transferImageToBase64 } from "@/utils/util";
// import cacheManager from "@/utils/cacheManager";
import appInfo from "@/utils/appInfo";

const hallLogic = {
  getAllHallRequest: () => {
    hallLogic.getSiteConfig();
    hallLogic.getGuideInstall();
    hallLogic.getOAuthConfig();
    hallLogic.getSmsConfig();
    hallLogic.getBrandActivate();
    hallLogic.getBrandLogo();
    hallLogic.getBrandAboutUs();
    hallLogic.getLanguageList();
    hallLogic.getCustomService();
    hallLogic.homeFloating();
    hallLogic.getHomeBannerList();
    hallLogic.getPlatFormList(null, (list: any[]) => {
      store.commit("games/savePlatFormList", { list });
    });
    hallLogic.getStaticBanner();
    // 获取活动列表让首页banner有需要跳转活动内页用
    activityLogic.getActivityList("0");
    // 获取代理广告内容
    agentLogic.getPublicitySetting();
    store.dispatch("redpack/getRedpackList");

    if (store.getters.loginSuccess) {
      hallLogic.getAllLoginRequest();
    } else {
      hallLogic.getAllPopup();
      // 获取首页游戏列表
      requestGameNavigationList();
    }
  },
  getAllLoginRequest: () => {
    requestPost(request.userApi.TokenCheck, null, () => {
      infoLogic.getNbUserInfo();
      infoLogic.getBalance();
      hallLogic.getCurrencyConfig();
      hallLogic.getAllPopup();
      // 获取首页游戏列表,登录成功之后需要再次请求,拿到游戏的收藏状态
      requestGameNavigationList();
      if (!store.getters.userType.isGuest) {
        // 游客登录的情况下，不请求vip和站内信等
        vipLogic.getVipConfig();
        vipLogic.getUserVipInfo();
        hallLogic.getUnread();
      }
    });
  },
  // 切换语言后需要刷新的接口
  changeLanguageAllRequest: () => {
    hallLogic.getSiteConfig();
    hallLogic.getSmsConfig();
    hallLogic.getBrandActivate();
    hallLogic.getBrandAboutUs();
    hallLogic.getCustomService();
    hallLogic.getHomeBannerList();
    hallLogic.getPlatFormList(null, (list: any[]) => {
      store.commit("games/savePlatFormList", { list });
    });
    activityLogic.getActivityList("0");
    agentLogic.getPublicitySetting();
    requestGameNavigationList();
    store.dispatch("redpack/getRedpackDocs");
  },
  getSiteConfig: () => {
    const params = {
      terminal: appInfo.terminalType,
      siteType: "2",
    };

    requestPost(nbConfigApi.GetSiteConfig, params, (response) => {
      // document.title = response.siteName;
      store.commit(types.UPDATE_SITECONFIG, response);
    });
  },
  getOAuthConfig: () => {
    const params = {
      deviceType: "3",
    };

    requestPost(nbConfigApi.OAuthConfig, params, (response) => {
      store.commit(types.UPDATE_OAUTH_CONFIG, response);
      (response as NBOAuthConfig[]).forEach((v) => {
        if (v.ocType === NBOcType.Google) {
          if (myWindow.googleInit) myWindow.googleInit(v.ocOauthId);
        } else if (v.ocType === NBOcType.Facebook) {
          if (myWindow.fbAsyncInit) myWindow.fbAsyncInit(v.ocOauthId);
        }
      });
    });
  },
  getCurrencyConfig: () => {
    requestPost(nbConfigApi.GetCurrencyList, null, (response) => {
      store.commit(types.UPDATE_CURRENCY_LIST, response);
    });
  },
  getSmsConfig: () => {
    requestPost(nbConfigApi.GetSmsConfig, null, (response) => {
      store.commit(types.UPDATE_SMS_CONFIG, response);
    });
  },
  getUnread: () => {
    requestPost(MessageApi.GetUnread, null, (response) => {
      store.commit("messages/saveNoReadNumber", {
        messages: Number(response?.messages ?? "0"),
        notice: Number(response?.notice ?? "0"),
      });
    });
  },
  getHomeBannerList: () => {
    const params = {
      brandType: 2,
      type: 2,
      language: store.getters.languageId,
    };

    requestPost(PublicityApi.GetList, params, (response) => {
      if (Array.isArray(response.result)) {
        store.commit(types.UPDATE_TOP_BANNER_LIST, response.result);
      }
    });
  },
  getPublicityPopup: () => {
    return new Promise<any[]>((resolve) => {
      const params = {
        brandType: 2,
        type: 1,
        language: store.getters.languageId,
      };

      requestPost(PublicityApi.GetList, params, (response) => {
        if (Array.isArray(response.result)) {
          resolve(response.result);
        }
      });
    });
  },
  // 广播列表
  getBroadcastList: (
    brandType: number,
    language: any,
    limit: number,
    page: number,
    callbackFunc: (value: any) => void,
    finallyFunc?: () => void,
    show?: boolean
  ) => {
    const params = {
      brandType,
      limit,
      page,
      language,
    };

    requestPostPromise(AnnouncementApi.GetList, params, null, show)
      .then((response) => {
        const res = response?.data as any | null;
        if (res == null) {
          return;
        }
        callbackFunc(res);
      })
      .finally(() => {
        finallyFunc && finallyFunc();
      });
  },
  getBrandActivate: () => {
    const params = {
      brandType: 2,
    };
    requestPost(BrandApi.GetBrandActivate, params, (response) => {
      if (response) {
        store.commit(types.UPDATE_BRAND_ACTIVATE, response);
      }
    });
  },
  // 获取logo
  getBrandLogo: () => {
    const params = {
      brandType: 2,
    };
    requestPost(BrandApi.GetBrandLogo, params, (response) => {
      const res = response as BrandConfig | null;
      if (res) {
        // document.head.querySelectorAll("link").forEach((link) => {
        //   if (link.rel === "icon") {
        //     link.parentNode?.removeChild(link);
        //   }
        // });
        // const link = document.createElement("link");
        // link.rel = "icon";
        // link.type = "image/png";
        // link.imageSizes = "32x32";
        // link.href = store.getters.imageUrl(res?.logo);
        // document.head.append(link);
        store.commit(types.UPDATE_BRAND_LOGO, res);
        store.commit("showDownloadBar", res?.status === "1");
      }
    });
  },
  getBrandAboutUs: () => {
    requestPost(BrandApi.GetBrandAboutUs, null, (response) => {
      if (response) store.commit(types.UPDATE_BRAND_ABOUT_US, response);
    });
  },
  // 获取语言列表
  getLanguageList: () => {
    requestPost(generalApi.GetLanguageList, null, (response) => {
      const res = response as LanguageConfig[] | null;
      if (res && res.length > 0) {
        store.commit(types.UPDATE_LANGUAGE_LIST, res);
      }
    });
  },
  // 弹窗相关
  getAllPopup: () => {
    // 请求弹窗前不管怎么样先清空所有弹窗
    store.commit("messagePopupBox/clearData");

    if (store.getters.loginSuccess && !store.getters.userType.isGuest) {
      Promise.all([
        hallLogic.homeFloatingPopup(),
        hallLogic.getCheckList(),
        hallLogic.getPublicityPopup(),
        hallLogic.getFirstRechargeDiscountPopUpStatus(),
        hallLogic.getRechargePopUpList(),
      ]).then((resArray) => {
        // 这里的顺序决定了弹窗的先后
        /** 处理人工充值弹窗 */
        configManualRecharge(resArray[4]);
        /** 处理首充弹窗 */
        configFirstCharge(resArray[3]);
        /** 处理好友助力 */
        configRoulette(resArray[0]);
        /** 处理签到弹窗 */
        configDailyCheck(resArray[1]);
        /** 处理消息宣传管理 */
        configPublicityPopup(resArray[2]);
      });
    } else {
      // 未登录不请求好友助力和签到
      Promise.all([hallLogic.getPublicityPopup()]).then((resArray) => {
        /** 处理消息宣传管理 */
        configPublicityPopup(resArray[0]);
      });
    }
  },
  // 获取砍一刀悬浮状态
  homeFloating: () => {
    requestPost(RouletteApi.HomeFloating, null, (response) => {
      if (response?.homeFloating === "1") {
        console.log("挂在侧边栏");
        store.commit("showSpinSidebar", true);
      }
    });
  },
  // 获取砍一刀弹窗
  homeFloatingPopup: () => {
    return new Promise<any>((resolve) => {
      requestPost(RouletteApi.HomeFloating, null, (response) => {
        resolve(response);
      });
    });
  },
  // 获取客服配置
  getCustomService: () => {
    requestPost(request.serviceApi.CustomerService, null, (response) => {
      store.commit(types.UPDATE_CUSTOMER_SERVICE, response);
    });
  },
  // 获取电子类平台列表
  getPlatFormList: (categoryId: any, callback: (value: any) => void) => {
    let category_id = "0";
    if (categoryId == CATEGORY_BTN_TYPE.slot) category_id = "3";
    else if (categoryId == CATEGORY_BTN_TYPE.live) category_id = "4";
    else if (categoryId == CATEGORY_BTN_TYPE.cards) category_id = "2";
    else if (categoryId == CATEGORY_BTN_TYPE.fishing) category_id = "1";
    const params = {
      categoryId: category_id,
    };
    requestPost(GamePlatformApi.GetList, params, (response) => {
      if (response && response.length > 0) {
        callback(response);
      }
    });
  },
  // 静态广告
  getStaticBanner: () => {
    const params = {
      brandType: 2,
    };
    requestPost(BrandApi.GetBrandPopUpAds, params, (response) => {
      const res = response as any[] | null;
      if (res && res.length > 0) {
        store.commit("saveStaticBanner", { list: res });
      }
    });
  },
  // 签到配置
  getCheckList: () => {
    return new Promise<any>((resolve) => {
      requestPostPromise(ChallengeApi.GetCheckInList, null)
        .then((response) => {
          const canClaim = response.data?.list?.some(
            (value: any) => value.claimed == "1"
          );
          store.commit(
            "activities/saveDailyCanClaim",
            canClaim && response.data.claimCondition.length == 0
          );
          resolve(response.data);
        })
        .catch(() => {
          resolve(null);
        });
    });
  },
  // 获取首充状态
  getFirstRechargeDiscountPopUpStatus: () => {
    return new Promise<any>((resolve) => {
      requestPost(ActivityApi.getActivityDiscountStatus, null, (response) => {
        if (response?.firstRechargePopUpStatus) {
          requestPost(
            ActivityApi.GetFirstRechargeDiscountPopUp,
            null,
            (data) => {
              resolve(data);
            }
          );
        } else resolve(null);
      });
    });
  },
  getGuideInstall: () => {
    requestPost(PublicityApi.GetGuideInstall, null, (response) => {
      if (Array.isArray(response)) {
        store.commit(types.UPDATE_GUIDE_INSTALL, null);
      } else {
        store.commit(types.UPDATE_GUIDE_INSTALL, response);
        // hallLogic.getPopUpTimeList();
      }
    });
  },
  getRechargePopUpList: () => {
    return new Promise<any[]>((resolve) => {
      requestPost(MessageApi.GetRechargePopUpList, null, (response) => {
        resolve(response?.list ?? []);
      });
    });
  },
  // getPopUpTimeList: () => {
  //   requestPost(PublicityApi.GetPopUpTimeList, null, (response) => {
  //     if (Array.isArray(response)) {
  //       store.commit(types.UPDATE_GUIDE_TIME, response);
  //     } else {
  //       store.commit(types.UPDATE_GUIDE_TIME, null);
  //     }
  //   });
  // },
};

const configRoulette = (config: any) => {
  const rouletteCache = customStore.get("roulette_config");
  if (rouletteCache) {
    const obj =
      typeof rouletteCache === "object"
        ? rouletteCache
        : JSON.parse(rouletteCache);
    if (config) {
      if (config.id != obj.id) {
        // 更换了活动，需要重置所有
        const cache = { hasShow: false, lastShowTime: 0, ...config };
        shouldShowRoulette(cache);
      } else {
        const cache = { ...obj, ...config };
        shouldShowRoulette(cache);
      }
    } else {
      shouldShowRoulette(obj);
    }
  } else {
    // 缓存不存在
    const cache = { hasShow: false, lastShowTime: 0, ...config };
    shouldShowRoulette(cache);
  }
};

const shouldShowRoulette = (cache: any) => {
  if (store.getters.loginSuccess && cache.homePopup === "1") {
    // 判断显示频率
    const fre = cache.popupFrequency;
    if (
      fre === "0" || // 每次登录都显示
      (fre === "1" &&
        Date.now() - (cache.lastShowTime ?? 0) > 24 * 60 * 60 * 1000) || // 24小时内一次
      (fre === "2" && !cache.hasShow)
    ) {
      // 一次性的
      cache.hasShow = true;
      cache.lastShowTime = Date.now();
      addPrizeWheelPopup(null);
    }
  }

  customStore.set("roulette_config", cache);
};

const configDailyCheck = (data: any) => {
  if (!data) return;

  if (store.getters.loginSuccess) {
    const dailyCache = customStore.get("daily_config");
    if (dailyCache) {
      const date = new Date(Number(dailyCache));
      const now = new Date();
      if (
        date.getDay() != now.getDay() ||
        date.getMonth() != now.getMonth() ||
        date.getFullYear() != now.getFullYear()
      ) {
        addDailyCheckPopup(data);
        customStore.set("daily_config", now.getTime());
      }
    } else {
      addDailyCheckPopup(data);
      customStore.set("daily_config", Date.now().toString());
    }
  }
};

const configPublicityPopup = (configArray: any[]) => {
  const publicityCache = customStore.get("publicity_config");
  if (publicityCache) {
    const oldCache = Array.isArray(publicityCache)
      ? publicityCache
      : JSON.parse(publicityCache);
    const newCache = configArray.map((v) => {
      const findObj = oldCache.find((cache: any) => cache.id === v.id);
      if (findObj) {
        // 本地存在这个弹窗配置，更新
        if (
          findObj.popUpTime != v.popUpTime ||
          findObj.popUpFrequency != v.popUpFrequency
        ) {
          // 本地和接口的 弹窗频率或弹窗频率不一致，重新弹窗
          return { hasShow: false, lastShowTime: 0, ...v };
        } else {
          return { ...findObj, ...v };
        }
      } else {
        // 不存在，新加入
        return { hasShow: false, lastShowTime: 0, ...v };
      }
    });
    shouldShowPublicity(newCache);
  } else {
    // 缓存不存在
    const newCache = configArray.map((v) => {
      return { hasShow: false, lastShowTime: 0, ...v };
    });
    shouldShowPublicity(newCache);
  }
};

const shouldShowPublicity = (cacheArray: any[]) => {
  cacheArray.forEach((v) => {
    if (
      (store.getters.loginSuccess && v.popUpTime === "1") ||
      (!store.getters.loginSuccess && v.popUpTime === "0")
    ) {
      // 登录后展示popUpTime === 1，登录前展示popUpTime === 0
      const fre = v.popUpFrequency;
      if (
        fre === "0" || // 每次登录都显示
        (fre === "1" &&
          Date.now() - (v.lastShowTime ?? 0) > 24 * 60 * 60 * 1000) || // 24小时内一次
        (fre === "2" && !v.hasShow)
      ) {
        // 一次性的
        v.hasShow = true;
        v.lastShowTime = Date.now();
        if (v.popUpType === "0") {
          addPublicityTextPopup(v);
        } else if (v.popUpType === "1") {
          addPublicityImagePopup(v);
        }
      }
    }
  });

  customStore.set("publicity_config", cacheArray);
  console.log("configPublicity", cacheArray);
};

const configFirstCharge = (response: any) => {
  if (!response) return;
  // 缓存的时间戳
  const firstChargeCache = customStore.get("first_charge_config");
  if (firstChargeCache) {
    // 当前时间戳
    const currentTime = Date.now();
    // 下一次弹窗的时间戳
    const nextShowTime =
      +firstChargeCache +
      (response?.frequency > 0 ? response.frequency * 60 * 60 * 1000 : 0);
    // 如果下一次弹窗的时间戳小于等于当前时间戳就弹窗
    if (nextShowTime <= currentTime) {
      addFirstRechargeDiscountPopup(response);
      customStore.set("first_charge_config", currentTime);
    }
  } else {
    addFirstRechargeDiscountPopup(response);
    customStore.set("first_charge_config", Date.now());
  }
};

const configManualRecharge = (configArray: any[]) => {
  configArray.forEach((v) => {
    addManualRechargePopup(v);
  });
};

export default hallLogic;
