import myWindow from "@/utils/myWindow";
import {
  AGENT_CODE,
  APPSFLEER_ID,
  APP_IDS,
  CHANNEL_CODE,
  FB_CLID,
} from "@/utils/consts";

let gtagPlatform = "H5";
let terminalType = "h5";
let deviceTypeId = "5";

const isAndroidWebView = () => {
  const userAgent = navigator.userAgent || navigator.vendor;
  return /Android/i.test(userAgent) && /wv/.test(userAgent);
};

const isIOSWebView = () => {
  const userAgent = navigator.userAgent || navigator.vendor;
  // 判断是否为 iOS 设备
  const isIOS = /iPhone|iPad|iPod/i.test(userAgent);
  // 判断是否为 WebKit
  const isWebKit = /AppleWebKit/i.test(userAgent);
  // iOS WebView 通常不包含 'Safari' 字符串
  const isSafari = /Safari/i.test(userAgent);
  // iOS WebView 包含 'AppleWebKit' 但不包含 'Safari'
  return isIOS && isWebKit && !isSafari;
};

const isWebApp = () => {
  const mqStandAlone = "(display-mode: standalone)";
  const isStandalone = window.matchMedia(mqStandAlone).matches;
  const isIOSStandalone = (window.navigator as any).standalone === true;
  return isStandalone || isIOSStandalone;
};

if (isAndroidWebView()) {
  gtagPlatform = "Android_App";
  terminalType = "android";
  deviceTypeId = "4";
} else if (isIOSWebView()) {
  gtagPlatform = "Ios_App";
  terminalType = "ios";
  deviceTypeId = "3";
} else if (isWebApp()) {
  gtagPlatform = "PWA";
  terminalType = "pwa";
  deviceTypeId = "6";
}

console.log("terminalType::", terminalType);
console.log("deviceTypeId::", deviceTypeId);

interface AppInfoConfig {
  gtagPlatform: string;
  terminalType: string;
  deviceTypeId: string;
  deviceId: string | null;
  agentCode: string | null;
  channelCode: string | null;
  appsFlyerId: string | null;
  appIds: string | null;
  fbclid: string | null;
}

const appInfo: AppInfoConfig = {
  gtagPlatform,
  terminalType,
  deviceTypeId,
  deviceId: null,
  agentCode: null,
  channelCode: null,
  appsFlyerId: null,
  appIds: null,
  fbclid: null,
};

const signupParams = localStorage.getItem("signupParams");
if (signupParams && signupParams.length > 0) {
  // 落地页带上的渠道和代理码
  try {
    const temp = JSON.parse(signupParams);
    appInfo.agentCode = temp.agentCode;
    appInfo.channelCode = temp.channelCode;
  } catch {
    //
  }
}

// 除了落地页，如果链接上也有，那么以链接上为主
const agentCode =
  typeof myWindow.usfa == "function" ? myWindow.usfa(AGENT_CODE) : null;
if (agentCode) {
  appInfo.agentCode = agentCode;
  localStorage.setItem(AGENT_CODE, agentCode);
} else {
  if (!appInfo.agentCode) {
    // 落地页拿不到才从这里拿
    appInfo.agentCode = localStorage.getItem(AGENT_CODE);
  }
}

const channelCode =
  typeof myWindow.usfa == "function" ? myWindow.usfa(CHANNEL_CODE) : null;
if (channelCode) {
  appInfo.channelCode = channelCode;
  localStorage.setItem(CHANNEL_CODE, channelCode);
} else {
  if (!appInfo.channelCode) {
    // 落地页拿不到才从这里拿
    appInfo.channelCode = localStorage.getItem(CHANNEL_CODE);
  }
}

const appsFlyerId =
  typeof myWindow.usfa == "function" ? myWindow.usfa(APPSFLEER_ID) : null;
if (appsFlyerId) {
  appInfo.appsFlyerId = appsFlyerId;
  localStorage.setItem(APPSFLEER_ID, appsFlyerId);
} else {
  appInfo.appsFlyerId = localStorage.getItem(APPSFLEER_ID);
}

const appIds =
  typeof myWindow.usfa == "function" ? myWindow.usfa(APP_IDS) : null;
if (appIds) {
  appInfo.appIds = appIds;
  localStorage.setItem(APP_IDS, appIds);
} else {
  appInfo.appIds = localStorage.getItem(APP_IDS);
}

const fbclid =
  typeof myWindow.usfa == "function" ? myWindow.usfa(FB_CLID) : null;
if (fbclid) {
  appInfo.fbclid = fbclid;
  localStorage.setItem(FB_CLID, fbclid);
} else {
  appInfo.fbclid = localStorage.getItem(FB_CLID);
}

export default appInfo;
