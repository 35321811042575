import { Module } from "vuex";
import { State } from "@/store/state";
import {
  AgentDistributionRule,
  AgentInvitationCode,
  DistributionRebateStatistics,
} from "@/interface";

export interface AgentState {
  becomeAgent: any;
  invitationCode: AgentInvitationCode | null;
  invitationApps: any;
  publicitySetting: any;
  agentRules: string;
  rebateStatistics: DistributionRebateStatistics | null;
  distributionRule: AgentDistributionRule[];
}

export const agent: Module<AgentState, State> = {
  namespaced: true,
  state: {
    becomeAgent: null,
    invitationCode: null,
    invitationApps: null,
    publicitySetting: null,
    agentRules: "",
    rebateStatistics: null,
    distributionRule: [],
  },
  getters: {
    getBecomeAgent(state) {
      return state.becomeAgent;
    },
    getInvitationCode(state) {
      return state.invitationCode;
    },
    getInvitationApps(state) {
      return state.invitationApps;
    },
    getPublicitySetting(state) {
      return state.publicitySetting;
    },
    getAgentRules(state) {
      return state.agentRules;
    },
    getRebateStatistics(state) {
      return state.rebateStatistics;
    },
    getDistributionRule(state) {
      return state.distributionRule;
    },
  },
  actions: {},
  mutations: {
    reset(state) {
      // state.becomeAgent = null;
      state.invitationCode = null;
      state.rebateStatistics = null;
      state.invitationApps = null;
      // state.publicitySetting = null;
      // state.agentRules = "";
      // state.distributionRule = [];
    },
    saveBecomeAgent(state, payload) {
      state.becomeAgent = payload?.data;
    },
    saveInvitationCode(state, payload) {
      state.invitationCode = payload?.data;
    },
    saveInvitationApps(state, payload) {
      state.invitationApps = payload?.data;
    },
    savePublicitySetting(state, payload) {
      state.publicitySetting = payload?.data;
    },
    saveAgentRules(state, payload) {
      state.agentRules = payload?.data;
    },
    saveRebateStatistics(state, payload) {
      state.rebateStatistics = payload?.data;
    },
    saveDistributionRule(state, payload) {
      state.distributionRule = payload?.data;
    },
  },
};
