enum CacheKey {
  DEMO_ACCOUNT = 21,
}

const increaseCache = (key: CacheKey | string, value: any) => {
  try {
    localStorage.setItem(key.toString(), JSON.stringify(value));
  } catch (err) {
    localStorage.setItem(key.toString(), value.toString());
  }
};

const deleteCache = (key: CacheKey | string) => {
  localStorage.removeItem(key.toString());
};

const queryCache = (key: CacheKey | string, deff?: any): any => {
  const res = localStorage.getItem(key.toString());
  if (res == null || res == "" || res == "{}") {
    return deff ?? null;
  }

  try {
    return JSON.parse(res);
  } catch (err) {
    return res;
  }
};

const getCookies = (name: string) => {
  const arrStr = document.cookie.split("; ");
  for (let i = 0; i < arrStr.length; i++) {
    const temp = arrStr[i].split("=");
    if (temp[0] == name) {
      return decodeURI(temp[1]);
    }
  }

  return "";
};

export default {
  CacheKey,
  increaseCache,
  deleteCache,
  queryCache,
  getCookies,
};
