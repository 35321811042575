import { store } from "@/store";
import types from "@/store/mutation-types";

const data: any = {};

const get = (key: string) => {
  if (typeof data[key] === "undefined") {
    return window.localStorage.getItem(key);
  }
  return data[key];
};

const set = (key: string, value: any) => {
  data[key] = value;
  if (key === "token") {
    if (value) {
      window.localStorage.setItem("token", value);
      store.commit(types.UPDATE_TOKEN, value);
    } else {
      window.localStorage.removeItem("token");
      store.commit(types.UPDATE_TOKEN, null);
    }
  }
  if (key === "identity") {
    if (value) {
      store.commit(types.UPDATE_USERTYPE, value);
    } else {
      store.commit(types.UPDATE_USERTYPE, "");
    }
  }
  if (key === "sms_last_time") {
    if (value) {
      window.localStorage.setItem("sms_last_time", value);
    }
  }
  if (key === "roulette_config") {
    if (value) {
      window.localStorage.setItem("roulette_config", JSON.stringify(value));
    } else {
      window.localStorage.removeItem("roulette_config");
    }
  }
  if (key === "daily_config") {
    if (value) {
      window.localStorage.setItem("daily_config", value);
    } else {
      window.localStorage.removeItem("daily_config");
    }
  }
  if (key === "publicity_config") {
    if (value) {
      window.localStorage.setItem("publicity_config", JSON.stringify(value));
    } else {
      window.localStorage.removeItem("publicity_config");
    }
  }
  if (key === "first_charge_config") {
    if (value) {
      window.localStorage.setItem("first_charge_config", value);
    } else {
      window.localStorage.removeItem("first_charge_config");
    }
  }
  if (key === "device-token") {
    if (value) {
      window.localStorage.setItem("device-token", value);
    } else {
      window.localStorage.removeItem("device-token");
    }
  }
};

export default {
  get,
  set,
};
